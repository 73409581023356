import { MenuItem } from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto } from '../../../api';
import companyApi from '../../../data-access/company-api';
import DeleteButton from '../../../ui/action-menu/delete-button/delete-button';

export interface CompanyDeleteMenuItemProps extends React.ComponentPropsWithoutRef<'button'> {
  company: CompanyDto;
  isDeletable: boolean;
  afterDelete?: () => void;
}

const CompanyDeleteMenuItem = React.forwardRef<HTMLButtonElement, CompanyDeleteMenuItemProps>(
  ({ company, isDeletable, afterDelete }, ref) => {
    const { t } = useTranslation(['company']);

    return (
      <MenuItem
        ref={ref}
        isDisabled={!isDeletable}
        disableReason={t('action.deleteFailedReason')}
        color="text.error"
        icon={<FontAwesomeIcon icon={faTrashAlt} />}
        as={DeleteButton<CompanyDto>}
        businessObject={company}
        objectName={company.name}
        deleteObject={({ id }: CompanyDto) => companyApi.deleteCompany({ id })}
        objectTypeToDelete={t('company')}
        afterDelete={afterDelete}
        objectTypeWithArticleNominative={t('companyWithArticle')}
        objectTypeWithArticleGenitive={t('company_with_article_genetive')}
      >
        {t('action.delete')}
      </MenuItem>
    );
  },
);

export default CompanyDeleteMenuItem;
